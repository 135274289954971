	/*
  	Flaticon icon font: Flaticon
  	Creation date: 12/07/2020 13:17
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("Flaticon.eot");
  src: url("Flaticond41d.eot?#iefix") format("embedded-opentype"),
       url("Flaticon.woff2") format("woff2"),
       url("Flaticon.woff") format("woff"),
       url("Flaticon.ttf") format("truetype"),
       url("Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
font-style: normal;
}

.flaticon-factory:before { content: "\f100"; }
.flaticon-mail:before { content: "\f101"; }
.flaticon-phone:before { content: "\f102"; }
.flaticon-mechanical-arm:before { content: "\f103"; }
.flaticon-automation:before { content: "\f104"; }
.flaticon-lab-tool:before { content: "\f105"; }
.flaticon-oil-drill:before { content: "\f106"; }
.flaticon-robotic-arm:before { content: "\f107"; }
.flaticon-darts:before { content: "\f108"; }
.flaticon-customer:before { content: "\f109"; }
.flaticon-security-purposes:before { content: "\f10a"; }
.flaticon-artificial-intelligence:before { content: "\f10b"; }
.flaticon-3d-print:before { content: "\f10c"; }
.flaticon-defribillator:before { content: "\f10d"; }
.flaticon-right-quotation-sign:before { content: "\f10e"; }
.flaticon-mechanic:before { content: "\f10f"; }
.flaticon-cpu:before { content: "\f110"; }
.flaticon-database-storage:before { content: "\f111"; }
.flaticon-data-server:before { content: "\f112"; }
.flaticon-lecture:before { content: "\f113"; }